import {Injectable} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subject, Subscription} from 'rxjs';
//import { LocaleSettings } from 'primeng/calendar';
import {registerLocaleData} from '@angular/common';
//import { PrimeNGConfig } from 'primeng/api';
import localeFr from '@angular/common/locales/fr';
import {PrimeNGConfig} from "primeng/api";

@Injectable({
  providedIn: 'root'
})
export class AppTranslateService {
  public language = new Subject<string>();
  public localeDateFormat: string | undefined;
  //public calendarLanguage: LocaleSettings;
  public dayOrderOfTheWeek: number[] | undefined;
  private subscription = new Subscription();

  private availableLanguages = ['fr'];
  private defaultLanguage = 'fr';

  constructor(private translateService: TranslateService, public primeNGConfig: PrimeNGConfig) {
    registerLocaleData(localeFr, 'fr');

    this.language.subscribe(lang => this.translateService.use(lang));
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.updateCalendarLanguage(event.lang);
    });

    translateService.addLangs(this.availableLanguages);
    translateService.setDefaultLang(this.defaultLanguage);

    let browserLang = translateService.getBrowserLang();
    if (!browserLang) {
      browserLang = this.defaultLanguage;
    }
    const usedLanguage = this.availableLanguages.indexOf(browserLang) !== -1 ? browserLang : this.defaultLanguage;

    this.language.next(usedLanguage);
    this.updateCalendarLanguage(usedLanguage);

    this.subscription = this.translateService.stream('primeng').subscribe(data => {
      this.primeNGConfig.setTranslation(data);
    });

  }

  private updateCalendarLanguage(lang: string) {
    switch (lang) {
      case 'fr':
        this.localeDateFormat = 'dd/mm/y';
        /*Le dimanche est le 7ème jour dans cette culture*/
        this.dayOrderOfTheWeek = [6, 0, 1, 2, 3, 4, 5];
        break;
    }
  }
}
