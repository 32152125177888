import {ChangeDetectorRef, Component} from '@angular/core';
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {CommonModule, NgTemplateOutlet} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'app-custom-confirm-dialog',
    templateUrl: './custom-confirm-dialog.component.html',
    imports: [
        ConfirmDialogModule,
        NgTemplateOutlet,
        TranslateModule,
        CommonModule
    ],
    standalone: true
})
export class CustomConfirmDialogComponent {}
