import {Injectable} from '@angular/core';
import {CurrentDoctorInfo, DoctorRepository, MemberRepository} from '@app/core/repositories';
import {Router} from '@angular/router';
import {BehaviorSubject, timeout} from 'rxjs';
import {WavenetToastService} from '@app/core/services/wavenet-toast.service';

@Injectable({
    providedIn: 'root',
})
export class MemberRoleService {
    public currentMember = new BehaviorSubject<CurrentDoctorInfo | undefined | null>(null);
    public lastSuccessfullRefresh = 3601;

    constructor(
        private router: Router,
        private memberRepository: MemberRepository,
        private doctorRepository: DoctorRepository,
        private toastService: WavenetToastService
    ) {
        setInterval(() => {
            this.lastSuccessfullRefresh -= 1;
            if(this.lastSuccessfullRefresh === 0){
                this.disconnectUser();
            }
        }, 1000);
    }

    public refreshLastSuccessfullRequest(): void {
        this.lastSuccessfullRefresh = 3601;
    }

    public disconnectUser(): void {
        this.memberRepository.logout().subscribe({
            next: () => {
                this.toastService.showSuccessToast('ACCOUNT_DISCONNECTED_SUCCESS');
                this.removeCurrentMember();
            }
            ,
            error: () => this.toastService.showErrorToast('ACCOUNT_DISCONNECTED_ERROR')
        });
    }

    public removeCurrentMember(): void {
        this.currentMember.next(null);
        this.router.navigate(['/account/']);
    }

    public refreshCurrentMember(): Promise<boolean> {
        return this.doctorRepository.getCurrentDoctorInfo().toPromise().then(
            (m) => {
                this.currentMember.next(m);
                this.refreshLastSuccessfullRequest();
                return true;
            },
            (err) => {
                this.currentMember.next(null);
                return false;
            }
        );
    }

    public isConnected(): boolean {
        return !!this.currentMember.value;
    }
}
