<app-custom-toast></app-custom-toast>
<app-custom-confirm-dialog></app-custom-confirm-dialog>

@if(this.globalService.isLoading | async){
  <ng-container>
    <div class="loading">
      <img class="login--logo" src="assets/images/proNutriConsult/PNC-Logo.svg" alt="pronutri-logo">
      <div class="logo__title">ProNutriConsult</div>
      <div class="lds-dual-ring"></div>
    </div>
  </ng-container>
}

@if(!hasDeclineInstall && !isRunningStandalone() && promptEvent){
  <ng-container>
    <div [class]="'install-pwa-container'">
      <button pButton
              class="button__primary"
              (click)="installPWA()">{{'INSTALL' | translate}}</button>
      <i class="pi pi-times" (click)="installDecline()"></i>
    </div>

  </ng-container>
}

@if(!hasDeclineInstall && isIosAndNotInstalledYet() && !showSafariAdvice){
  <ng-container>
    <div [class]="'install-pwa-container'">
      <button pButton
              class="button__primary"
              (click)="showSafariAdvice = true">{{'INSTALL' | translate}}</button>
      <i class="pi pi-times" (click)="installDecline()"></i>
    </div>
  </ng-container>
}

@if(showSafariAdvice){
  <ng-container>
    <div [class]="'safari-install-advice safari-install-advice--open'">
      <button type="button" class="safari-install-advice__close" (click)="showSafariAdvice = false">
        <i class="pi pi-times"></i>
      </button>
      <div>{{'IOS_ADDTOPHONE1' | translate}}</div>
      <div>{{'IOS_ADDTOPHONE2' | translate}} <i className="icon-share-apple"></i> {{'IOS_ADDTOPHONE3' | translate}} <strong>{{'IOS_ADDTOPHONE4' | translate}}</strong></div>
    </div>
  </ng-container>
}

@defer {
  <router-outlet></router-outlet>
}

