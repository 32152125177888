import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '@env/environment';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AppTranslateModule} from '@app/app-translate.module';
import {CustomToastModule} from '@app/shared/components/custom-toast/custom-toast.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {API_BASE_URL, DoctorRepository, MemberRepository} from '@app/core/repositories';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {UpdateDateHttpInterceptor} from '@app/shared/interceptors';
import {ButtonModule} from "primeng/button";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {
    CustomConfirmDialogComponent
} from "@app/shared/components/custom-confirm-dialog/custom-confirm-dialog.component";
import {UnauthorizedHttpInterceptor} from "@app/shared/interceptors/unauthorized.interceptor";
import { NgOptimizedImage } from '@angular/common'



@NgModule({
  declarations: [
    AppComponent,
  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppTranslateModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        CustomToastModule,
        ButtonModule,
        ConfirmDialogModule,
        CustomConfirmDialogComponent,
        NgOptimizedImage
    ],
  providers: [
    {provide: API_BASE_URL, useValue: environment.apiUrl},
    {provide: HTTP_INTERCEPTORS, useClass: UpdateDateHttpInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: UnauthorizedHttpInterceptor, multi: true},
    MessageService,
    MemberRepository,
    DoctorRepository,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
