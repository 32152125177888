import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MessageService} from 'primeng/api';
import {ConstantsService} from "@app/core/services/constants.service";

@Injectable({
  providedIn: 'root'
})
export class WavenetToastService {
  constructor(private messageService: MessageService, private translateService: TranslateService, public constantsService: ConstantsService) {
  }

  public showSuccessToast(summaryKey: string, detailsKey?: string, summaryInterpolateParams?: Object, detailsInterpolateParams?: Object): void {
    const summary = this.translateService.instant(summaryKey, summaryInterpolateParams);
    if (detailsKey) {
      const details = this.translateService.instant(detailsKey, detailsInterpolateParams);
      this.messageService.add({
        key: 'bc',
        severity: 'success',
        summary,
        detail: details,
        life: this.constantsService.toastLifeTimeMillis
      });
    } else {
      this.messageService.add({
        key: 'bc',
        severity: 'success',
        summary,
        life: this.constantsService.toastLifeTimeMillis
      });
    }
  }

  public showErrorToast(summaryKey: string, detailsKey?: string, e?: any, summaryInterpolateParams?: Object, detailsInterpolateParams?: Object): void {
    const summary = this.translateService.instant(summaryKey, summaryInterpolateParams);
    if (detailsKey) {
      const details = this.translateService.instant(detailsKey, detailsInterpolateParams);
      this.messageService.add({
        key: 'bc',
        severity: 'error',
        summary,
        detail: details,
        life: this.constantsService.toastLifeTimeMillis
      });
    } else {
      this.messageService.add({key: 'bc', severity: 'error', summary, life: this.constantsService.toastLifeTimeMillis});
    }
    if (e) {
      console.error(e);
    }
  }
}
