<p-confirmDialog key="confirmationDisconnect" [closable]="false" [header]=" 'GLOBAL_DISCONNECTED' | translate"
                 width="450" #cd [baseZIndex]="20000" icon="pi pi-exclamation-triangle">
	<ng-template pTemplate="header">
		<ng-container
				[ngTemplateOutlet]="confirmationHeader"
				[ngTemplateOutletContext]="{confirmation:cd}">
		</ng-container>
	</ng-template>
	<ng-template pTemplate="footer">
		<button class="button__primary" type="button" pButton [label]="'GLOBAL_OK'|translate"
		        (click)="cd.accept()"></button>
	</ng-template>
</p-confirmDialog>

<ng-template #confirmationHeader let-confirmation="confirmation">
	<div class="group--title">
		<div class="title">
        @if(confirmation?.confirmation?.header){
          {{confirmation.confirmation.header}}
        } @else {
          {{confirmation.confirmationOptions.header}}
        }
		</div>
	</div>
	<div class="dialog-actions">
		<div class="close" (click)="confirmation.close($event)"><i class="icon-minicross"></i></div>
	</div>
</ng-template>
