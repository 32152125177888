import {Component, HostListener, OnInit} from '@angular/core';
import {GlobalService} from "@app/core/services/global.service";
import {TranslateService} from "@ngx-translate/core";
import {SwUpdate, VersionReadyEvent} from "@angular/service-worker";
import {filter} from "rxjs";
import {AppTranslateService} from "@app/core/services";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  public hasDeclineInstall: boolean = sessionStorage.getItem('hasDeclineInstall') === 'true';
  public showSafariAdvice: boolean = false;

  constructor(private appTranslateService: AppTranslateService,
              public swUpdate: SwUpdate,
              public globalService: GlobalService,
              private translateService: TranslateService) {
    swUpdate.versionUpdates
      .pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'))
      .subscribe(evt => {
        // Reload the page to update to the latest version.
        document.location.reload();
      });
  }

  ngOnInit() {
    this.translateService.setDefaultLang('fr');
  }

  public promptEvent;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    e.preventDefault();
    this.promptEvent = e;
  }

  public installPWA() {
    this.promptEvent.prompt();
  }

  public isRunningStandalone(): boolean {
    return (window.matchMedia('(display-mode: standalone)').matches);
  }

  public isIosAndNotInstalledYet(): boolean {
    return window.matchMedia('(display-mode: browser)').matches && /iPad|iPhone|iPod/.test(navigator.userAgent);
  }

  installDecline() {
    this.hasDeclineInstall = true;
    sessionStorage.setItem('hasDeclineInstall', 'true');
  }
}
