import {Component, NgModule, OnInit} from '@angular/core';
import {ToastModule} from "primeng/toast";

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html'
})
export class CustomToastComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}

@NgModule({
  declarations: [CustomToastComponent],
  exports: [CustomToastComponent],
  imports: [
    ToastModule
  ],
  providers: []
})
export class CustomToastModule {
}
