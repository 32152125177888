import {Injectable} from '@angular/core';
import {FormArray, FormGroup} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {

  constructor() {
  }

  public updateChildrenValueAndValidity(form: FormGroup | FormArray, opts: { onlySelf?: boolean; emitEvent?: boolean; } = {}): void {
    Object.keys(form.controls).forEach((key) => {
      form.get(key).updateValueAndValidity(opts);
    });
  }

  public markAllAsDirty(form: FormGroup | FormArray, opts: { onlySelf?: boolean; } = {}): void {
    Object.keys(form.controls).forEach((key) => {
      form.get(key).markAsDirty(opts);
    });
  }

  public removeAllValidators(form: FormGroup | FormArray): void {
    Object.keys(form.controls).forEach((key) => {
      form.get(key).clearValidators();
    });
  }
}
