import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    public isLoading = new BehaviorSubject(true);

    public manageLoading(isLoading: boolean): void {
        this.isLoading.next(isLoading);
    }
}
