import { ConsultationDetails } from '@app/core/repositories/nswag-generated-file';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ConsultationEditionService {

    private displayFormSubject = new Subject<{visibility: boolean, consultation?: ConsultationDetails}>();
    private consultationSavedSubject = new Subject<ConsultationDetails>();

    constructor() { }

    public get displayObservable(): Observable<{visibility: boolean, consultation?: ConsultationDetails}> {
      return this.displayFormSubject.asObservable();
    }

    public changeFormVisibility(visibility: boolean, data?: ConsultationDetails): void {
      this.displayFormSubject.next({visibility, consultation: data});
    }

    public consultationSaved(consultation: ConsultationDetails): void {
      this.consultationSavedSubject.next(consultation);
    }
}
