import {BehaviorSubject} from 'rxjs';
import {SelectItem} from 'primeng/api';
import {ConsultationRepository, ConsultInListingRepository, ReferenceDataRepository,} from '@app/core/repositories';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ListValuesHelperService {
  private $diseases: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
  private $consultationTypes: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
  private $consultationReasons: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);

  constructor(
      public translate: TranslateService,
      private consultInListingRepository: ConsultInListingRepository,
      private consultationRepository: ConsultationRepository,
  ) {
    this.consultInListingRepository.getDiseasesDropdownValues().subscribe((data) => {
      this.$diseases.next(
          data.map((c: any) => {
            return {label: c.label, value: c.value.id};
          })
      );
    });

    this.consultationRepository.getConsultationTypes(false).subscribe((data) => {
      this.$consultationTypes.next(data.map(consultationType => ({
        label: consultationType.name,
        value: consultationType.id
      })));
    });

    this.consultationRepository.getConsultationReasonsForDropdown().subscribe((data) => {
      this.$consultationReasons.next(data.map(dropdownValue => ({
        label: dropdownValue.label,
        value: dropdownValue.id
      })));
    });

  }

  public getConsultationTypes(): BehaviorSubject<SelectItem[]> {
    return this.$consultationTypes;
  }

  public getDiseases(): BehaviorSubject<SelectItem[]> {
    return this.$diseases;
  }

  public getConsultationReasons(): BehaviorSubject<SelectItem[]> {
    return this.$consultationReasons;
  }
}
