import {Injectable} from "@angular/core";
import {MemberRoleService} from "@app/core/services";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root',
})
export class LoginService {

    constructor(private memberRoleService: MemberRoleService,
                private router: Router) {}

    public async isLoggedIn(): Promise<boolean> {
        if (!this.memberRoleService.isConnected() && !await this.memberRoleService.refreshCurrentMember()) {
            this.router.navigate(['/account/login']);

            return false;
        }
        return true;
    }
}
