import {inject, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginService} from "@app/core/guards/login.service";

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./features/account/account.module').then((m) => m.AccountModule)
  },
  {
    path: '',
    loadChildren: () => import('./features/content/content.module').then((m) => m.ContentModule),
    canActivate: [() => inject(LoginService).isLoggedIn()]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
