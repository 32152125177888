import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {MemberRoleService} from "@app/core/services";
import {TranslateService} from "@ngx-translate/core";
import {ConfirmationService} from "primeng/api";

@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {

    constructor(
        private confirmationService: ConfirmationService,
        public translate: TranslateService,
        public memberRoleService: MemberRoleService
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap({
                next: (event: HttpEvent<any>) => {},
                error: (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (!err.url.includes('GetCurrentDoctorInfo')) {
                            if (err.status === 401) {
                                this.confirmationService.confirm({
                                    key: 'confirmationDisconnect',
                                    message: this.translate.instant('WARNING_DISCONNECTED'),
                                    accept: () => {
                                        this.memberRoleService.removeCurrentMember();
                                    },
                                });
                            } else {
                                this.memberRoleService.refreshLastSuccessfullRequest();
                            }
                        }
                    }
                }
            }));
    }

}
