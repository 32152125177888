import {HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

@Injectable()
export class UpdateDateHttpInterceptor implements HttpInterceptor {

  private dateRegex = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])(T|\s)(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');

  intercept(req: HttpRequest<any>, next: HttpHandler) : Observable<HttpEvent<any>> {
    if (req.method === 'POST' || req.method === 'PUT') {
      const newBody = this.parseBody(req.body);
      if (newBody) {
        this.shiftRequestDates(newBody);
        req = req.clone({ body: newBody });
      }
    }

    const paramsSeparator = '?';
    if (req.method === 'GET' && req.urlWithParams.includes(paramsSeparator)) {
      const splitUrl = req.urlWithParams.split(paramsSeparator);
      let httpParams = new HttpParams({ fromString: splitUrl[1] });

      httpParams.keys().forEach(key => {
        const value = httpParams.get(key);
        if (this.dateRegex.test(value)) {
          httpParams = httpParams.set(key, this.toUTCDate(value).toISOString());
        }
      });
      req = req.clone({ params: httpParams, url: splitUrl[0] });
    }


    return next.handle(req)
               .pipe(
                  mergeMap(event => {
                    if(!(event instanceof HttpResponse) || navigator.userAgent.indexOf("Safari") == -1 || !(event.body instanceof Blob))
                    {
                      return of(event);
                    }
                    const body: {} = event.body;
                    return this.blobToText(body).pipe(
                      map((val) => {
                        let newBody = this.parseBody(val);
                        if (newBody) {
                          this.shiftResponseDates(newBody);
                          const blob = new Blob([JSON.stringify(newBody)], { type: 'application/json' });
                          return event.clone({ body: blob });
                        }
                        return event;
                      })
                    );
                  })
                );
  }

  private blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
        if (!blob) {
            observer.next("");
            observer.complete();
        } else {
            let reader = new FileReader();
            reader.onload = event => {
                observer.next((<any>event.target).result);
                observer.complete();
            };
            reader.readAsText(blob);
        }
    });
  }

  private parseBody(body: any) {
    if (typeof body === 'string') {
      try {
        return JSON.parse(body);
      } catch {}
    } else if (typeof body === 'object') {
      return body;
    }
    return null;
  }

  private shiftRequestDates(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (value instanceof Date || (typeof value === 'string' && this.dateRegex.test(value))) {
        body[key] = this.toUTCDate(value);
      } else if (typeof value === 'object') {
        this.shiftRequestDates(value);
      }
    }
  }

  private shiftResponseDates(body) {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];
      if (typeof value === 'string' && this.dateRegex.test(value)) {
        const parsedValue = value.match(this.dateRegex);
        const date = new Date(+parsedValue[1], +parsedValue[2] - 1, +parsedValue[3], +parsedValue[5], +parsedValue[6], +parsedValue[7]);
        body[key] = date.toISOString();
      } else if (typeof value === 'object') {
        this.shiftResponseDates(value);
      }
    }
  }

  private toUTCDate(value: Date | string): Date {
    if (typeof value === 'string') {
      value = new Date(value);
    }
    return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(),
      value.getHours(), value.getMinutes(), value.getSeconds()));
  }
}
